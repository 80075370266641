@font-face {
  font-family: 'Gentium Book Plus';
  src: url('fonts/Brygada1918-Regular.ttf');
  src: url('fonts/Brygada1918-Regular.ttf') format('truetype');
}

.App {
  font-family: 'Gentium Book Plus', serif;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 400px;
  padding: 16px;
}

.App h1 {
  margin-top: 0;
}

.footer {
  font-size: 0.8em;
}

@media only screen and (max-width: 512px) {
  .App {
    width: 300px;
  }
}